<template>
    <div class="BannerBox">
        <span>热点资讯，</span>
        <ui style="display: inline">看看大西洲最新动态</ui>
        <swiper-container :centered-slides="false" :breakpoints="{
            1920: {
                slidesPerView: 1,
                spaceBetween: 10
            },
        }" @progress="onProgress" @slidechange="onSlideChange">
            <swiper-slide>
                <div class="redian">
                    <div class="redianbox1">
                        <img style="width: 100%;" src="../assets/redian1.png">
                    </div>
                    <div style="display: flex;">
                        <div class="redianbox2">
                            <img style="width: 100%;" src="../assets/redian2.png">
                        </div>
                        <div class="redianbox2">
                            <img style="width: 100%;" src="../assets/redian3.png">
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="redian">
                    <!-- <span>热点资讯，</span>
                    <ui style="display: inline">看看大西洲最新动态</ui> -->
                    <div class="redianbox1">
                        <img style="width: 100%;" src="../assets/redian4.png">
                    </div>
                    <div style="display: flex;">
                        <div class="redianbox2">
                            <img style="width: 100%;" src="../assets/redian5.png">
                        </div>
                        <div class="redianbox2">
                            <img style="width: 100%;" src="../assets/redian6.png">
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper-container>
    </div>
</template>
  
<script>
import { register } from 'swiper/element/bundle';
register();
export default {
    setup() {
        const spaceBetween = 10;
        const onProgress = (e) => {
            const [swiper, progress] = e.detail;
            console.log(progress)
        };

        const onSlideChange = (e) => {
            console.log('slide changed')
        }
        console.log();
        return {
            spaceBetween,
            onProgress,
            onSlideChange,
        };
    },
    name: '',
    props: {
    },
    components: {
    },
    mounted() {
    }
}
</script>
  
<style scoped>
.BannerBox {
    position: relative;
    margin-top: 6.25rem;
    width: 100%;
    
    span {
        color: #1C1C1C;
        font-weight: bold;
        font-size: 28;
        font-family: Helvetica;
    }

    ui {
        color: #7C7B75;
        font-size: 24;
        font-weight: bold;
        font-family: Helvetica;
    }

}

.redian {
    margin-top: 0.25rem;

    span {
        color: #1C1C1C;
        font-weight: bold;
        font-size: 28;
        font-family: Helvetica;
    }

    ui {
        color: #7C7B75;
        font-size: 24;
        font-weight: bold;
        font-family: Helvetica;
    }

    .redianbox1 {
        width: 91.25rem;
        height: 18.25rem;
        background-color: #e0f7fd;
        margin-top: 1.25rem;
        position: relative;
        border-radius: 1%;
    }

    .redianbox2 {
        width: 45rem;
        height: 25.3125rem;
        background-color: #e0f7fd;
        margin-top: 1.25rem;
        margin-right: 1.25rem;
        position: relative;
    }
}
</style>
  