<template>
    <div style="overflow-x:hidden">
        <div class="top_bg">
            <img src="../assets/logo.png">
            <p>大西洲</p>
            <p>业务板块</p>
            <p>产品与服务</p>
            <p>案例展示</p>
            <p>社会责任</p>
            <p>联系我们</p>
        </div>
        <div style="width: 100%;margin-top:1rem">
            <Banner1 style="width: 98%;position: relative;left: 1%;" />
        </div>
        <div class="banner2">
            <span>数字化产品、服务、解决方案，</span>
            <ui style="display: inline">助你拥抱这个数字化时代</ui>
        </div>
        <Banner2 style="position:relative;left:11%;" />
        <AnliBanner style="position: relative;left:10%; margin-top: 6.25rem; width: 93.5rem;" />
        <Shehui style="position:relative;left:11%;margin-top:6.25rem" />
        <!-- <Meiti style="position:relative;left:11%;margin-top:6.25rem" /> -->
        <RedianBanner style="position: relative;left:11%; " />
        <Bottom />
    </div>
</template>
  
<script>
import Banner1 from './Banner1.vue';
import Banner2 from './Banner2.vue';
import Bottom from './Bottom.vue';
import AnliBanner from './AnliBanner.vue';
import Shehui from './Shehui.vue'
import RedianBanner from './RedianBanner.vue';
import Meiti from './Meiti.vue';

export default {
    name: 'HelloWorld',
    data() {
        return {
            msg: 'Welcome to Your Vue.js App'
        }
    },
    components: {
        Banner1, Banner2, Bottom, AnliBanner, Shehui, RedianBanner, Meiti
    },
    methods: {
        goVideo(name) {
            localStorage.setItem("videoName", "0" + name);
            this.$router.push('/Video');
        }
    }
}
</script>
  
<style scoped lang="less">
.top_bg {
    height: 4.375rem;
    width: 100%;
    background-color: #EBECF0;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        margin: 0 1.25rem;
        font-size: 0.8rem;
        color: #000002;
        font-weight: bold;
        font-family: Helvetica;
    }

    img {
        margin: 0;
        width: 6.25rem;
    }


}

.banner1 {
    height: 50.625rem;

}

.banner2 {
    margin-top: 6.25rem;
    margin-bottom: 1.25rem;

    span {
        color: #1C1C1C;
        font-weight: bold;
        margin-left: 14.2438rem;
        font-size: 28;
        font-family: Helvetica;
    }

    ui {
        color: #7C7B75;
        font-size: 24;
        font-weight: bold;
        font-family: Helvetica;
    }

    .banner1_box {
        width: 0%;
    }

}

.qifa {
    margin-top: 3.25rem;

    span {
        color: #1C1C1C;
        font-weight: bold;
        margin-left: 14.2438rem;
        font-size: 28;
        font-family: Helvetica;
    }

    ui {
        color: #7C7B75;
        font-size: 24;
        font-weight: bold;
        font-family: Helvetica;
    }

    .box1 {
        width: 21.875rem;
        height: 21.875rem;
        background-color: #fffae7;
        margin-top: 1.25rem;
        margin-left: 1.25rem;
        border-radius: 0.5%;
    }

    .box1s {
        width: 80%;
        position: relative;
        left: 10%;
    }

    .box1Word {
        width: 100%;
        height: 3.25rem;
        background-color: #ffefb1;
        position: relative;
        top: 84%;
    }
}

.redian {
    margin-top: 6.25rem;

    span {
        color: #1C1C1C;
        font-weight: bold;
        margin-left: 13.2438rem;
        font-size: 28;
        font-family: Helvetica;
    }

    ui {
        color: #7C7B75;
        font-size: 24;
        font-weight: bold;
        font-family: Helvetica;
    }

    .redianbox1 {
        width: 91.25rem;
        height: 18.25rem;
        background-color: #e0f7fd;
        margin-top: 1.25rem;
        position: relative;
        left: 11%;
        border-radius: 1%;
    }

    .redianbox2 {
        width: 45rem;
        height: 25.3125rem;
        background-color: #e0f7fd;
        margin-top: 1.25rem;
        margin-right: 1.25rem;
        position: relative;
        left: 11%;
    }
}

.bottom_box {
    overflow: hidden;
    background-color: #ebecf0;
    width: 100%;
    height: 44.0625rem;
    margin-top: 12.5rem;

    .bottom_box_word {
        text-align: center;
        color: #464748;
        padding: 5rem 0;
    }

    .bottom_left {
        display: inline-block;
        width: 44.5%;
        position: relative;
        left: 5.5%;

        span {
            color: #878682;
        }

        .qr_box {
            position: relative;
            left: 25%;

            img {
                padding-top: 2rem;
                padding-bottom: 2rem;
            }

            span {
                position: relative;
                left: 18%;
                font-weight: bold;
            }

            ui {
                color: #878682;
                font-weight: bold;
                position: relative;
                left: 16%;
            }
        }
    }
}
</style>
  