<template>
  <router-view></router-view>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import Home from './components/Home.vue';
import Banner1 from './components/Banner1.vue'

export default {
  name: 'App',
  components: {
    HelloWorld, Home, Banner1
  }
}
</script>

<style>
* {
  padding: 0%;
  margin: 0%;
}

.jz {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bb {
  border: 1px solid red;
}

.lineBox {
  display: inline-block;
}
</style>
