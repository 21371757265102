<template>
    <div class="bigBox" style="width: 100%;">
        <div style="display: flex;">
            <div class="" style="width: 50%;">
                <div class="" style="width: 100%;text-align: center;color:#303030;font-weight:bold">关注我们</div>
                <div style="width: 100%;padding-top:2rem;display:flex;justify-content: center;">
                    <div class="" style="text-align: center;padding:1rem;width:50%;">
                        <div class="img_box " style="position:relative;left:65%">
                            <img style="width: 9.5rem;" src="../assets/qr.png" />
                        </div>
                    </div>
                    <div class="" style="text-align: center;padding:1rem;width:50%;">
                        <div class="img_box " style="position:relative;left:10%">
                            <img style="width: 9.5rem;" src="../assets/qr.png" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div style="width: 50%;">
                <div class="" style="width: 100%;text-align: center;color:#303030;font-weight:bold">联系我们</div>
                <div style="width: 100%;text-align: center;padding-top:2rem;display:flex;justify-content: center;">
                    <div style="text-align: center;padding:1rem;">
                        <span style="position: relative;left:-4%">【扫码关注】</span>
                        <div class="img_box ">
                            <img style="width: 9.5rem;height:9.5rem" src="../assets/qr.png">
                        </div>
                        <span style="position: relative;left:-2%">大西洲科技公众微信号</span>
                    </div>
                    <div style="text-align: center;padding:1rem;">
                        <span>【扫码关注】</span>
                        <div class="img_box ">
                            <img style="width: 9.5rem;height:9.5rem" src="../assets/qr.png">
                        </div>
                        <span>大西洲科技小程序</span>
                    </div>
                </div>
            </div> -->
            <div class="" style="width: 50%;">
                <div class="" style="width: 100%;text-align: center;color:#303030;font-weight:bold">关注我们</div>
                <div style="width: 100%;padding-top:2rem;display:flex;justify-content: center;">
                    <div class="" style="text-align: center;padding:1rem;width:50%;">
                        <div class="img_box " style="position:relative;left:65%">
                            <img style="width: 9.5rem;" src="../assets/qr.png" />
                        </div>
                    </div>
                    <div class="" style="text-align: center;padding:1rem;width:50%;">
                        <div class="img_box " style="position:relative;left:10%">
                            <img style="width: 9.5rem;" src="../assets/qr.png" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="text-align: center;margin-top:6rem">
            <!-- <span>京ICP备10214630号 营业执照 无线电发射设备销售备案编号11201910351200(示例)</span>
            <br>
            <span>京ICP备10214630号 营业执照</span> -->
            <span></span>
            <br>
            <span></span>
        </div>
    </div>
</template>
  
<script>
export default {
    name: '',
    props: {
    }
}
</script>
  
<style scoped>
.bigBox {
    padding-top: 5.5rem;
    background-color: #ebecf0;
    margin-top: 12.5rem;

    span {
        color: #9f9f9d;
        font-weight: bold;
        font-size: 2rem;
        zoom: 0.5;
    }

    .word {
        color: #353334;
        font-weight: bold;

        span {
            padding-left: 30%;
            font-size: 1.0rem;
        }

        margin-bottom: 3%;
    }

    .img_box {
        width: 9.5rem;
        height: 9.5rem;

        img {
            width: 100%;
        }
    }

}
</style>
  