<template>
    <div class="BannerBox">
        <span>应用案例，</span>
        <ui style="display: inline;">启发创意</ui>
        <swiper-container class="swiperContainer" :centered-slides="true" :pagination="{
            hideOnClick: true
        }" :breakpoints="{
    1920: {
        slidesPerView: 1,
    },
}" @progress="onProgress" @slidechange="onSlideChange">
            <swiper-slide>
                <div class="qifa">
                    <div class="box1s" style="display: flex">
                        <div @click="goVideo(1)" class="box1">
                            <img style="width: 100%;" src="../assets/anli1.png">
                        </div>
                        <div @click="goVideo(2)" class="box1">
                            <img style="width: 100%;" src="../assets/anli2.png">
                        </div>
                        <div @click="goVideo(3)" class="box1">
                            <img style="width: 100%;" src="../assets/anli3.png">
                        </div>
                        <div @click="goVideo(4)" class="box1">
                            <img style="width: 100%;" src="../assets/anli4.png">
                        </div>
                    </div>
                    <div class="box1s" style="display: flex">
                        <div @click="goVideo(5)" class="box1">
                            <img style="width: 100%;" src="../assets/anli5.png">
                        </div>
                        <div @click="goVideo(6)" class="box1">
                            <img style="width: 100%;" src="../assets/anli6.png">
                        </div>
                        <div @click="goVideo(7)" class="box1">
                            <img style="width: 100%;" src="../assets/anli7.png">
                        </div>
                        <div @click="goVideo(8)" class="box1">
                            <img style="width: 100%;" src="../assets/anli8.png">
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="qifa">
                    <div class="box1s" style="display: flex">
                        <div @click="goVideo(9)" class="box1">
                            <img style="width: 100%;" src="../assets/anli9.png">
                        </div>
                        <div @click="goVideo(10)" class="box1">
                            <img style="width: 100%;" src="../assets/anli10.png">
                        </div>
                        <div @click="goVideo(11)" class="box1">
                            <img style="width: 100%;" src="../assets/anli11.png">
                        </div>
                        <div @click="goVideo(12)" class="box1">
                            <img style="width: 100%;" src="../assets/anli12.png">
                        </div>
                    </div>
                    <div class="box1s" style="display: flex">
                        <div @click="goVideo(13)" class="box1">
                            <img style="width: 100%;" src="../assets/anli13.png">
                        </div>
                        <div @click="goVideo(14)" class="box1">
                            <img style="width: 100%;" src="../assets/anli14.png">
                        </div>
                        <div @click="goVideo(15)" class="box1">
                            <img style="width: 100%;" src="../assets/anli15.png">
                        </div>
                        <div @click="goVideo(16)" class="box1">
                            <img style="width: 100%;" src="../assets/anli16.png">
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper-container>
    </div>
</template>

<script>
import { register } from 'swiper/element/bundle';
register();

export default {
    setup() {
        const spaceBetween = 0;
        const onProgress = (e) => {
            const [swiper, progress] = e.detail;
            console.log(progress)
        };

        const onSlideChange = (e) => {
            console.log('slide changed')
        }
        console.log();
        return {
            spaceBetween,
            onProgress,
            onSlideChange,
        };
    },
    name: '',
    props: {
    },
    data() {
        return {
        }
    },
    methods: {
        goVideo(name) {
            if (name < 10) {
                name = "0" + name;
            }
            localStorage.setItem("videoName", this.$mainUrl + "/" + name + ".mp4");
            this.$router.push('/Video');
        }
    }
}
</script>

<style scoped lang="less">
.BannerBox {
    span {
        color: #1C1C1C;
        font-weight: bold;
        margin-left: 2rem;
        font-size: 28;
        font-family: Helvetica;
    }

    ui {
        color: #7C7B75;
        font-size: 24;
        font-weight: bold;
        font-family: Helvetica;
    }

    wiper-container {}

    swiper-slide {
        height: 47.5rem;
        border-radius: 0.5rem;
        position: relative;
    }

    .qifa {
         width: 100%;
        .box1 {
            width: 21.875rem;
            height: 21.875rem;
            margin-top: 1.25rem;
            margin-left: 1.25rem;
            border-radius: 0.5%;
        }

        .box1s {
            width: 100%;
            position: relative;
        }

        .box1Word {
            width: 100%;
            height: 3.25rem;
            position: relative;
        }
    }

}
</style>