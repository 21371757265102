<template>
  <div class="BannerBox">
    <swiper-container :centered-slides="true" :pagination="{
      hideOnClick: true
    }" :breakpoints="{
  1920: {
    slidesPerView: 1,
  },
}" @progress="onProgress" @slidechange="onSlideChange">
      <swiper-slide>
        <img @click="goChangzhou(1)" style="width: 100%;" src="../assets/test1.png">
      </swiper-slide>
      <swiper-slide> <img @click="goChangzhou(2)" style="width: 100%;" src="../assets/test2.png"></swiper-slide>
      <swiper-slide> <img @click="goChangzhou(3)" style="width: 100%;" src="../assets/test3.png"></swiper-slide>
    </swiper-container>
  </div>
</template>
  
<script>
import { register } from 'swiper/element/bundle';
register();
export default {
  setup() {
    const spaceBetween = 0;
    const onProgress = (e) => {
      const [swiper, progress] = e.detail;
      console.log(progress)
    };

    const onSlideChange = (e) => {
      console.log('slide changed')
    }
    console.log();
    return {
      spaceBetween,
      onProgress,
      onSlideChange,
    };
  },
  name: '',
  props: {
  },
  components: {
  },
  mounted() {

  },
  methods: {
    goChangzhou(type) {
      if (type == 1) {
        window.location.href = "https://www.lihuachao.com/resource/webgl/Build_WebGL/"
      } else if (type == 2) {
        window.location.href = "https://www.lihuachao.com/resource/webgl/sun/"
      } else if (type == 3) {
        window.location.href = "https://www.lihuachao.com/resource/webgl/sun2/"
      }
    }
  }
}
</script>
  
<style scoped lang="less">
.BannerBox {
  width: 100%;
  padding: 0%;
  margin: 0%;
  overflow: hidden;

  wiper-container {}

  swiper-slide {
    background-color: #dafffa;
    height: 50.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
  }
}
</style>
  