<template>
    <div class="BannerBox">
        <span>社会责任，</span>
        <ui style="display: inline">科技助推人文关怀</ui>
        <div style="height: 1.25rem;"></div>
        <swiper-container :centered-slides="false" :breakpoints="{
            0: {
                slidesPerView: 5.7,
                spaceBetween: 10
            },

        }" @progress="onProgress" @slidechange="onSlideChange">
            <swiper-slide>
                <img @click="goVideo(1)" style="width: 100%;" src="../assets/she1.png">
            </swiper-slide>
            <swiper-slide>
                <img @click="goVideo(2)" style="width: 100%;" src="../assets/she2.png">
            </swiper-slide>
            <swiper-slide>
                <img @click="goVideo(3)" style="width: 100%;" src="../assets/she3.png">
            </swiper-slide>
            <swiper-slide>
                <img @click="goVideo(4)" style="width: 100%;" src="../assets/she4.png">
            </swiper-slide>
            <swiper-slide>
                <img @click="goVideo(5)" style="width: 100%;" src="../assets/she5.png">
            </swiper-slide>
            <swiper-slide>
                <img @click="goVideo(6)" style="width: 100%;" src="../assets/she6.png">
            </swiper-slide>
            <swiper-slide>
                <img @click="goVideo(7)" style="width: 100%;" src="../assets/she7.png">
            </swiper-slide>
        </swiper-container>
    </div>
</template>
    
<script>
import { register } from 'swiper/element/bundle';
register();
export default {
    setup() {
        const spaceBetween = 10;
        const onProgress = (e) => {
            const [swiper, progress] = e.detail;
            console.log(progress)
        };

        const onSlideChange = (e) => {
            console.log('slide changed')
        }
        console.log();
        return {
            spaceBetween,
            onProgress,
            onSlideChange,
        };
    },
    name: '',
    props: {
    },
    components: {
    },
    mounted() {
    },
    methods: {
        goVideo(name) {
            localStorage.setItem("videoName", this.$mainUrl + "/she" + name + ".mp4");
            this.$router.push('/Video');
        }
    }
}
</script>
    
<style scoped lang="less">
.BannerBox {
    position: relative;
    width: 90%;
    padding: 0%;
    margin: 0%;
    overflow: hidden;

    span {
        color: #1C1C1C;
        font-weight: bold;
        font-size: 28;
        font-family: Helvetica;
    }

    ui {
        color: #7C7B75;
        font-size: 24;
        font-weight: bold;
        font-family: Helvetica;
    }

    wiper-container {
        border: 1px solid yellow;
    }

    swiper-slide {
        border-radius: 2%;
        height: 28.125rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
    