<template>
    <div class="BannerBox">
        <swiper-container :centered-slides="false" :breakpoints="{
            0: {
                slidesPerView: 3.5,
                spaceBetween: 10
            },
        }" @progress="onProgress" @slidechange="onSlideChange">
            <swiper-slide>
                <img style="width: 100%;" src="../assets/shuzi1.png">
            </swiper-slide>
            <swiper-slide>
                <img style="width: 100%;" src="../assets/shuzi2.png">
            </swiper-slide>
            <swiper-slide>
                <img style="width: 100%;" src="../assets/shuzi3.png">
            </swiper-slide>
            <swiper-slide>
                <img style="width: 100%;" src="../assets/shuzi4.png">
            </swiper-slide>
        </swiper-container>
    </div>
</template>
    
<script>
import { register } from 'swiper/element/bundle';
register();
export default {
    setup() {
        const spaceBetween = 10;
        const onProgress = (e) => {
            const [swiper, progress] = e.detail;
            console.log(progress)
        };

        const onSlideChange = (e) => {
            console.log('slide changed')
        }
        console.log();
        return {
            spaceBetween,
            onProgress,
            onSlideChange,
        };
    },
    name: '',
    props: {
    },
    components: {
    },
    mounted() {
    }
}
</script>
    
<style scoped lang="less">
.BannerBox {
    position: relative;
    width: 90%;
    padding: 0%;
    margin: 0%;
    overflow: hidden;

    wiper-container {
        border: 1px solid yellow;
    }

    swiper-slide {
        border-radius: 2%;
        height: 40.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
    