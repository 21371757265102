<template>
    <div>
        <video width="100%" height="100%" controls>
            <!-- <source src="../../public/01.mp4" type="video/mp4"> -->
            <source :src="videoResource" type="video/mp4">
        </video>
    </div>
</template>
  
<script>
export default {
    name: '',
    props: {
    },
    data() {
        return {
            videoResource: "",
        }
    },
    mounted() {
        this.videoResource = localStorage.getItem("videoName");
    }
}
</script>
  
<style scoped></style>
  