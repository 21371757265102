import { createApp } from 'vue'
import App from './App.vue'
import '../src/js/rem.js'

import router from './router/index.js'


const app = createApp(App);
app.use(router);
app.mount('#app');

app.config.globalProperties.$mainUrl = "https://www.lihuachao.com/resource/video/guan/"